import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import queryString from 'query-string';
import pick from 'lodash.pick';
import get from 'lodash.get';
import {
    DEFAULT_TEMPLATES_OPTIONS, TEMPLATES_PATH
} from '../../constants';
import {
    fetchTemplates, selectOptions, selectTemplatesPerPage, setOptions
} from '../components/Templates/templatesSlice';
import getValidPackagesParams from '../utils/getValidPackagesParams';
import {selectUser} from '../components/Login/userSlice';

export default function useTemplatesPageNavigate(onSuccess, navigateImmediately) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const options = useSelector(selectOptions);
    const templatesPerPage = useSelector(selectTemplatesPerPage);
    const user = useSelector(selectUser);
    const personalPreferences = get(user, 'data.personal_preferences', {});

    return async (pageOptions = {}, shouldResetPages) => {
        const newOptions = {
            ...DEFAULT_TEMPLATES_OPTIONS,
            ...options,
            ...getValidPackagesParams(pageOptions, personalPreferences)
        };
        if (shouldResetPages) {
            newOptions.from = DEFAULT_TEMPLATES_OPTIONS.from;
            newOptions.to = templatesPerPage;
        }

        const filteredNewOptions = pick(newOptions, [
            'from',
            'to',
            'sort',
            'dir',
            'search',
            'visibility'
        ]);
        const newQuery = queryString.stringify(filteredNewOptions);

        dispatch(setOptions(filteredNewOptions));

        if (navigateImmediately) {
            navigate(`${TEMPLATES_PATH}?${newQuery}`);
        }

        const wasSuccessful = await dispatch(fetchTemplates());
        if (wasSuccessful) {
            if (onSuccess) {
                onSuccess();
            }
            if (!navigateImmediately) {
                navigate(`${TEMPLATES_PATH}?${newQuery}`);
            }
        }
    };
}
